<template>
  <div>
    <b-form @submit.prevent="updateServerApp">
      <b-form-group label="Nom" label-for="name-input" label-cols-lg="auto">
        <b-input-group>
          <b-form-input
              id="name-input"
              v-model="$v.sa.name.$model"
              type="text"
              required
              placeholder="Renseignez un nom"
              :state="$v.sa.name.$dirty ? !$v.sa.name.$invalid : null"
          />
          <b-input-group-append>
            <b-button type="submit" variant="primary">Modifier</b-button>
          </b-input-group-append>
        </b-input-group>
        <b-form-invalid-feedback :state="!$v.sa.name.$invalid">
          <span v-if="!$v.sa.name.required">Vous devez renseigner ce champ</span>
        </b-form-invalid-feedback>
      </b-form-group>
    </b-form>
    <b-form-group
        label="Clé de signature de token"
        label-for="token-signature-key-input"
    >
      <b-input-group>
        <template #prepend>
          <CopyToClipBoardButton :toCopy="$v.sa.tokenSignatureKey.$model"/>
        </template>
        <b-form-input
            id="token-signature-key-input"
            v-model="$v.sa.tokenSignatureKey.$model"
            type="text"
            required
            disabled
        />
        <template #append>
          <ReloadButton @reload="resetTokenSignatureKey"/>
        </template>
      </b-input-group>
      <b-form-invalid-feedback :state="!$v.sa.name.$invalid">
        <span v-if="!$v.sa.name.required">Vous devez renseigner ce champ</span>
      </b-form-invalid-feedback>
    </b-form-group>
  </div>
</template>

<script>
import {required} from "vuelidate/lib/validators";

import CopyToClipBoardButton from "@/components/buttons/CopyToClipBoardButton";
import ReloadButton from "@/components/buttons/ReloadButton";

export default {
  props: {
    serverApp: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      sa: Object.assign({}, this.serverApp), // copy state to avoid mutation
    };
  },
  validations: {
    sa: {
      name: {required},
      tokenSignatureKey: {required},
    },
  },
  methods: {
    async updateServerApp() {
      try {
        await this.$store.dispatch("serverApps/updateServerApp", this.sa);
      } catch (e) {
        await this.$swal.fire({
          icon: "error",
          title:
              "Une erreur est survenue lors de la mise à jour de l'application serveur",
          text: e.message,
        });
        return;
      }

      await this.$swal.fire({
        icon: "success",
        title: "Application serveur modifiée",
      });
    },
    async resetTokenSignatureKey() {
      try {
        await this.$store.dispatch("serverApps/resetServerAppTokenSignatureKey", this.sa);
      } catch (e) {
        await this.$swal.fire({
          icon: "error",
          title:
              "Une erreur est survenue lors de la réinitialisation de la clé de signature de token de l'application serveur",
          text: e.message,
        });
        return;
      }
      this.sa = Object.assign({}, this.serverApp);
    },
  },
  components: {
    CopyToClipBoardButton,
    ReloadButton,
  },
};
</script>

<style scoped>
button {
  border-color: #e9ecef;
}

input#token-signature-key-input {
  filter: blur(3px);
  transition: 0.5s -webkit-filter ease;
}

input#token-signature-key-input:hover {
  filter: blur(0);
}
</style>
